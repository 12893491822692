<template>
  <app-layout>
    <page-header>
      Masinile mele
      <template #end><vendor-cars-index-button-add /> </template>
    </page-header>
    <page-section>
      <!-- <v-card class="max-w-xl m-auto "> -->
      <v-card>
        <loader v-if="loading" class="my-5" />
        <template v-else>
          <template v-if="totalRows">
            <vendor-cars-index-table
              :rows="rows"
              @car:destroy="destroyCar($event)"
            />
            <pagination
              @change:page="index($event)"
              :total="meta.total"
              :current_page="meta.current_page"
              :from="meta.from"
              :to="meta.to"
              :next_page_url="links.next"
              :prev_page_url="links.prev"
            />
          </template>
          <no-results v-else class="m-5" />
        </template>
      </v-card>
    </page-section>
  </app-layout>
</template>

<script>
import vendorCarsApi from "@/api/resources/vendorCarsApi";
import AppLayout from "../layout/AppLayout.vue";
import VendorCarsIndexTable from "../components/VendorCarsIndexTable.vue";
import VCard from "../components/VCard.vue";
import Loader from "../components/Loader.vue";
import Pagination from "../components/Pagination.vue";
import NoResults from "../components/NoResults.vue";
import VendorCarsIndexButtonAdd from "../components/VendorCarsIndexButtonAdd.vue";

import PageHeader from "../components/PageHeader.vue";
import PageSection from "../components/PageSection.vue";

export default {
  components: {
    AppLayout,
    VendorCarsIndexTable,
    VCard,
    Loader,
    Pagination,
    NoResults,
    VendorCarsIndexButtonAdd,
    PageHeader,
    PageSection,
  },

  data() {
    return {
      rows: [],
      meta: {},
      links: {},
      loading: true,
    };
  },

  computed: {
    totalRows() {
      return this.rows.length;
    },
  },

  methods: {
    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    index(page = null) {
      this.loading = true;
      vendorCarsApi
        .index(page && { page })
        .then((data) => {
          this.rows = data.data;
          this.meta = data.meta;
          this.links = data.links;

          this.scrollTop();
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    destroyCar(car) {
      this.loading = true;
      vendorCarsApi

        .destroy(car)
        .then(() => {
          this.index();
          this.scrollTop();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },

  mounted() {
    this.index();
  },
};
</script>

<style></style>
