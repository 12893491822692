<template>
  <router-link class="btn-primary" :to="{ name: 'cars.create' }">
    <v-button-icon>
      <PlusIcon />
    </v-button-icon>
    Adauga masina
  </router-link>
</template>

<script>
import { PlusIcon } from "@heroicons/vue/solid";
import VButtonIcon from "./VButtonIcon.vue";

export default {
  components: { PlusIcon, VButtonIcon },
};
</script>

<style></style>
