<template>
  <v-table>
    <v-thead>
      <tr>
        <v-table-th>Marca</v-table-th>
        <v-table-th>Actiuni</v-table-th>
      </tr>
    </v-thead>

    <v-tbody>
      <tr v-for="row in rows" :key="row.id">
        <v-table-td>
          <p class="text-gray-900 whitespace-no-wrap">
            {{ row.name }}
          </p>
        </v-table-td>
        <v-table-td>
          <button
            class="btn-danger"
            @click="$emit('car:destroy', row.id)"
          >
            Sterge
          </button>
        </v-table-td>
      </tr>
    </v-tbody>
  </v-table>
</template>

<script>
import VTable from "./VTable.vue";
import VTableTd from "./VTableTd.vue";
import VTableTh from "./VTableTh.vue";
import VTbody from "./VTbody.vue";
import VThead from "./VThead.vue";
export default {
  components: { VTableTd, VTable, VThead, VTableTh, VTbody },

  props: {
    rows: {
      type: Array,
    },
  },
};
</script>

<style></style>
