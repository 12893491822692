import { VENDOR_CARS, VENDOR_CARS_SHOW } from "../endpoints";
import api from "../api";

export default {
    index(query = null) {
        let url = VENDOR_CARS;

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },

    store(data) {
        return api.post(VENDOR_CARS, data);
    },

    destroy(car = null) {
        if (car === null) return Promise.reject('Car id was null');

        const url = VENDOR_CARS_SHOW.replace(":car", car);

        return api.delete(url);
    }
}